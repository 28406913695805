export const params = {
  ///////////////////////////////LOCAL UI///////////////////////////////
/*
     ui: "http://localhost:4200/",
       elastic: "https://api.moglix.com",
       images: "https://img.moglimg.com/",
      auth: "http://authqa.moglilabs.com",
      prod_supp:"http://localhost:8082",
      chat_demo: "http://localhost:8082",
      ems: "http://emsqa.moglilabs.com",
      old_prod_supplier: "http://localhost:8080",
      old_report_module: "http://localhost:8080", 
      online_redirect_path: "http://www.moglix.com/"
      saleOps_portal:"https://salesopsqa.moglilabs.com",
      price_engine: "https://price-engineqa.moglilabs.com"
  //audit_log: "http://catalogueauditlogapiqa.moglilabs.com/",
  //audit_log_appID:"J6M1-XEBNcuKOvdqxG9v",
  // google_login_id: "747938668116-r0dhm54vhrm7klo7c95o5l8028j6rcrs.apps.googleusercontent.com",
*/
  ///////////////////////////////QA UI///////////////////////////////

  /* ui: "https://catalog-qa1.moglilabs.com", //"https://cataloguiqa.moglix.com/",
   elastic: "https://api.moglix.com",
 
   // Old Path for Image 
   // images: "https://img.moglimg.com/",
   auth: "https://authqa.moglilabs.com",
   prod_supp: "https://catalogapi-qa1.moglilabs.com",
   ems: "https://emsqa.moglilabs.com",
   old_prod_supplier: "https://platformdata-qa1.moglilabs.com",//"https://platformdataqa2.moglix.com",
   old_report_module: "https://platformreportsqa.moglix.com",
   online_redirect_path: "https://www.qa.moglilabs.com/",
   // New Path for Images
   images: "https://s3.ap-south-1.amazonaws.com/imgqa.moglix.com/",
   // SaleOps portal
   saleOps_portal: "https://salesopsqa.moglilabs.com",
   api_gateway: "https://catalogueapi-dev.moglilabs.com",
   spark_module: "https://sparkapi.moglilabs.com",
   // Offer Price config
   price_engine: "https://price-engineqa.moglilabs.com",
   user_management: "https://authqappe.moglilabs.com", //"https://authqa.ppecovid19.com",
   user_management_secret_key: "Q2F0YWxvZ2h0dHA6Ly9jYXRhbG9ndWlxYS5tb2dsaXguY29tLw==",
   //audit log
   audit_log: "https://catalogueauditlogapiqa.moglilabs.com",
   audit_log_appID: "J6M1-XEBNcuKOvdqxG9v",
   country_list: "http://platformdataqa2.moglix.com",
   subCategories: 'https://platformdataqa2.moglix.com',
   analyticsReport: "https://analysis.moglix.com/centralsyncreportapi",
   downloadReport: "https://analysis.moglix.com",
   uploadDocuments: "https://platformdataqa2.moglix.com/cassandraFeed",
   approval_endPoint: "https://catalogservice.moglix.com",
   platform_data_feed: "https://platformdatafeedservice.moglix.com",
   price_manager: 'https://pricemanagerapi.moglix.com',
   gridMaxSize: 7,
   //google login id
   google_login_id: "8394930684-drhtc5jrl9a8fv4m7285111cja6vmh3k.apps.googleusercontent.com"*/

  // Catalog dev new environment

  
  /*ui: "https://cataloguiqa.moglix.com/",
  elastic: "https://api.moglix.com",
  auth: "https://authqa.moglilabs.com",
  prod_supp: "https://catalogapi-dev.moglilabs.com",

  ems: "https://emsqa.moglilabs.com",
  old_prod_supplier: "https://platformdata-dev.moglilabs.com",
  old_report_module: "https://platformreports-dev.moglilabs.com",
  online_redirect_path: "https://www.qa.moglilabs.com/",
  api_gateway:"https://catalogsearch-dev.moglilabs.com",
  // New Path for Images
  images: "https://s3.ap-south-1.amazonaws.com/imgqa.moglix.com/",
  //feature images
  feature_images: "https://imgqa.moglix.com/",
  // SaleOps portal
  saleOps_portal: "https://salesopsqa.moglilabs.com",
  spark_module: "https://sparkapi.moglilabs.com",
  // Offer Price config
  price_engine: "https://price-engineqa.moglilabs.com",
  user_management: "https://authqappe.moglilabs.com", //"https://authqa.ppecovid19.com",
  user_management_secret_key: "Q2F0YWxvZ2h0dHA6Ly9jYXRhbG9ndWlxYS5tb2dsaXguY29tLw==",
  //audit log
  audit_log: "https://catalogueauditlogapiqa.moglilabs.com",
  audit_log_appID: "J6M1-XEBNcuKOvdqxG9v",
  //Online Module
  onlineMerchant:"https://online-merchandise.moglilabs.com",
  analyticsReport: "https://analysis.moglix.com/centralsyncreportapi",
  downloadReport: "https://analysis.moglix.com",
   approval_endPoint: "https://catalogservice-qa1.moglilabs.com",
  platform_data_feed: "https://platformdatafeedservice.moglix.com",
  price_manager: 'https://pricemanagerapi.moglix.com',
  gridMaxSize:30,
  google_login_id: "8394930684-drhtc5jrl9a8fv4m7285111cja6vmh3k.apps.googleusercontent.com",
  //cis portal
  cisPortal: 'https://cis.moglix.com/category-discovery',*/
  
  
  

  /*
   ui: "https://cataloguiqa.moglix.com/",
   elastic: "https://api.moglix.com",
   auth: "https://authqa.moglilabs.com",
   prod_supp: "https://catalogapi-dev.moglilabs.com",
   ems: "https://emsqa.moglilabs.com",
   old_prod_supplier: "https://platformdata-dev.moglilabs.com",
   old_report_module: "https://platformreports-dev.moglilabs.com",
   online_redirect_path: "https://www.qa.moglilabs.com/",
   // New Path for Images
   images: "https://s3.ap-south-1.amazonaws.com/imgqa.moglix.com/",
   // SaleOps portal
   saleOps_portal: "https://salesopsqa.moglilabs.com",
   spark_module: "https://sparkapi.moglilabs.com",
   // Offer Price config
   price_engine: "https://price-engineqa.moglilabs.com",
   user_management: "https://authqappe.moglilabs.com", //"https://authqa.ppecovid19.com",
   user_management_secret_key: "RHVtbWV5d3d3LnN1cHBseTEuY29t",
   //audit log
   audit_log: "https://catalogueauditlogapiqa.moglilabs.com",
   audit_log_appID: "J6M1-XEBNcuKOvdqxG9v",
   analyticsReport: "https://analysis.moglix.com/centralsyncreportapi",
   downloadReport: "https://analysis.moglix.com",
   approval_endPoint: "https://catalogservice.moglix.com",
   platform_data_feed: "https://platformdatafeedservice.moglix.com",
   price_manager: 'https://pricemanagerapi.moglix.com',
   google_login_id: "747938668116-r0dhm54vhrm7klo7c95o5l8028j6rcrs.apps.googleusercontent.com",
  */
  ///////////////////////////////PROD UI///////////////////////////////
  
  ui: "https://catalog.moglix.com/",
  elastic: "https://api.moglix.com",
  images: "https://img.moglimg.com/",
  auth: "http://authqa.moglilabs.com",
  feature_images: "https://img.moglimg.com/",
  prod_supp: "https://catalogapi.moglix.com",
  ems: "https://ems.moglix.com",
  old_prod_supplier: "https://platformdataprod.moglix.com",
  old_report_module: "https://platformreportsprod.moglix.com",
  api_gateway: "https://catalogsearch.moglix.com",
  online_redirect_path: "https://www.moglix.com/",
  saleOps_portal: "https://salesopsapi.moglix.com",
  spark_module: "https://sparkapi.moglix.com",
  price_engine: "https://price-engine.moglix.com",
  user_management: "https://centralauthapi.moglix.com",
  user_management_secret_key:
    "Q2F0YWxvZ2h0dHA6Ly9jYXRhbG9ndWlxYS5tb2dsaXguY29tLw==",
  audit_log: "https://catalogueauditlogapi.moglix.com",
  audit_log_appID: "8_6iwnIBUMUjVjL8Xj-e",
  country_list: "https://platformdataprod.moglix.com",
  analyticsReport: "https://analysis.moglix.com/centralsyncreportapi",
  downloadReport: "https://analysis.moglix.com",
  approval_endPoint: "https://catalogservice.moglix.com",
  platform_data_feed: "https://platformdatafeedservice.moglix.com",
  price_manager: "https://pricemanagerapi.moglix.com",
  //Online Module
  onlineMerchant: "https://catalog-assessment-service.moglix.com",
  gridMaxSize: 30,
  google_login_id:
    "335020530870-52e874a3gmjp4a7v6r5f042i8bgnhiik.apps.googleusercontent.com",
  //cis portal
  cisPortal: 'https://cis.moglix.com/category-discovery'
  
};
